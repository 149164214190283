import '../styles/main.scss';
import smoothscroll from 'smoothscroll-polyfill';
import Util from './modules/Utilities';
import Sidenav from './modules/Sidenav';
import Datepicker from './modules/Datepicker';

document.addEventListener('DOMContentLoaded', () => {
  smoothscroll.polyfill();
  Util.forEachPolyfill();
  Util.anchorsInit();

  new Sidenav();
  new Datepicker(document.getElementById('contact__datepicker'));
});

window.onload = () => {
  const lazyImages = document.querySelectorAll('[data-lazy-src]');
  lazyImages.forEach(image => {
    let source = image.getAttribute('data-lazy-src');
    image.style.backgroundImage = `url(${source})`;
  });
}

