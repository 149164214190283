export default class Sidenav {
  constructor() {
    this.DOM = {};
    this.DOM.sidenav = document.querySelector('.sidenav');

    this.toggleMenu = this.toggleMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
    this.init();
  }

  init() {
    if (!this.DOM.sidenav) return;
    setTimeout(() => this.DOM.sidenav.style.display = 'flex', 500);
    this.getChildren();
    this.bindEvents();
  }

  getChildren() {
    this.DOM.trigger = this.DOM.sidenav.querySelector('.sidenav__trigger');
    this.DOM.menu = this.DOM.sidenav.querySelector('.sidenav__menu');
  }

  bindEvents() {
    this.DOM.trigger.addEventListener('click', this.toggleMenu);
    this.DOM.menu.addEventListener('click', (e) => this.hideMenu(e));
  }

  toggleMenu() {
    this.DOM.menu.classList.toggle('is-visible');
    this.DOM.trigger.classList.toggle('is-active');
  }

  hideMenu(event) {
    if (event.target.className.includes('sidenav__menu is-visible')) {
      this.DOM.menu.classList.remove('is-visible');
      this.DOM.trigger.classList.remove('is-active');
    }
  }
}
